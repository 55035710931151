.newsletter-page{
    height: auto;
    position: relative;
    text-align: center;
    min-height: 100%;
    @media screen and (min-height : 855px){
        min-height: 850px;
    }
    @media screen and (min-width: 540px) and (min-height: 600px) {
        min-height: 840px;
    }
    .content-page{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
        // padding: 15px 0;
    }
    .title-page{
        font-size: 8vw;
        text-align: center;
        margin-top: 10px;
        @media screen and (min-width: 540px){
            font-size: 60px;
        }
    }
    .text-page{
        font-size: 4.1vw;
        line-height: 7vw;
        text-align: center;
        max-width: 90%;
        margin: 10px auto;
        @media screen and (min-width: 540px){
            font-size: 17px;
            line-height: 31px;
            max-width: 64%
        }
    }
    .input-block{
        width: 85%;
        margin: 20px auto;
        @media screen and (min-width: 540px){
            width: 65%;
        }
        input{
            width: 100%;
            max-width: 100%;
            height: 10vw;
            border: 1px solid black;
            border-radius: 100px;
            text-align: center;
            font-size: 4vw;
            outline: none;
            margin: 0;
            padding: 0;
            font-family: $font;
            @media screen and (min-width: 540px){
                height: 50px;
                font-size: 20px;
            }
        }
        &.error{
            input{
                border: 1px solid $red;
            }
        }
        .error{
            color: $red;
        }
        .condition-sms{
            font-size: 3vw;
            margin-top: 8px;
            @media screen and (min-width: 540px){
                font-size: 15px;
            }
        }
    }
    .checkbox-block{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 92%;
        margin: auto;
        -webkit-tap-highlight-color:transparent;
        @media screen and (min-width: 540px){
            width: 62%;
        }
        label{
            font-weight: 700;
            font-size: 3.8vw;
            width: 100%;
            outline: none;
            text-align: left;
            flex-shrink: 0;
            flex-grow: 0;
            span {
                flex-shrink: 0;
                flex-grow: 0;
                width: 18px;
                height: 18px;
            }
            @media screen and (min-width: 540px){
                font-size: 17px;
                span{
                    // width: 47px;
                    flex-shrink: 0;
                    flex-grow: 0;
                    width: 26px;
                    height: 26px;
                }
            }
        }
        &:focus {
            outline: none;
        }
    }
    .mandatory{
        font-size: 3vw;
        width: 90%;
        color: $red;

        margin: 10px auto;
        text-align: center;
        @media screen and (min-width: 540px){
            font-size: 15px;
            max-width:64%;
        }
    }
    .condition{
        font-size: 3vw;
        width: 90%;

        margin: 20px auto;
        text-align: left;
        @media screen and (min-width: 540px){
            font-size: 15px;
            max-width:64%;
        }
    }
    .frc-captcha {
        margin: 20px auto;
        width: 90%;
        @media screen and (min-width: 540px){
            font-size: 15px;
            max-width:64%;
        }
    }
    .btn-inscription{
        display: flex;
        width: 50vw;
        height: 12vw;
        margin: 25px auto 20px auto;
        box-shadow: 0px 3px 18px -14px $black;
        border: none;
        outline: none;
        span{
            font-size: 4.5vw;
            line-height: 12vw;
            width: 100%;
            height: 100%;
            display: block;
        }
        @media screen and (min-width: 540px){
            min-width: 180px;
            width: auto;
            height: 55px;
            span{
                font-size: 22px;
                line-height: 55px;
            }
        }
    }
    .ignore-sign{
        margin: 20px auto;
        text-decoration: underline;
        cursor: pointer;
    }
    .bottom-page{
        position: relative;
    }
}