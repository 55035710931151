.block-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    // max-width: 32%;
    margin: 10px auto 0px auto;
    .btn{
        width: 13vw;
        height: 13vw;
        position: relative;
        box-shadow: 0px 3px 11px -5px $black;
        margin: auto;
        img{
            width: 6vw;
            height: 6vw;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin: auto;
        }
        @media screen and (min-width: 540px){
            width: 60px;
            height: 60px;
            img{
                width: 50%;
                height: 50%;
            }
        }
        &.btn-exit{

        }
        &.btn-close{

        }
    }
}