.footer-block{
    padding-bottom: 5px;
    .block-citeo{
        padding-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .line-pict{
            width: 100%;
            height: 100%;
            min-height: 2px;
        }
        .logo-citeo{
            max-width: 25%;
            max-height: 56px;
            margin: 0 40px;
        }
    }
    .reglement-block{
        text-align: center;
        margin: 5px auto;
        a{
            text-decoration: underline;
            font-size: 4vw;
        }
        @media screen and (min-width: 540px){
            a{
                font-size: 16px;
            }
        }
    }
}
