/* COLOR */
$black: #000000;
$white: #ffffff;

$darkGrey: #7B7B7C;
$grey: #F4F4F4;

$lightGrey: #f8f8f8;
$greyTextModal:#737373;

$yellow: #FFED00;
$mustard: #FFC715;
$orange: #FFB232;
$green: #329004;
$greenlight: #3BC643;
$redDark: #993701;
$red: #E21B1B;
$blue: #5590e6;

$whitetransp: rgba(255, 255, 255, 0.95);
$blacktransp: rgba(0,0,0,0.6);
$shadow-light: rgba(0, 0, 0, 0.1);

/* SIZE */
$desktop-up: 1024px;
$mid-tablet: 900px;
$tablet-up: 768px;