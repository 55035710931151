.score-page{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    .header-block{
        min-height: 21vw;
        @media screen and (min-width: 540px){
            min-height: 100px;
        }
    }
    .content-page{
        width: 100%;
        // min-height: 340px;
        flex: 1;
    }
    .score-text {
        font-family: $fontSC;
        font-size: 10vw;
        text-align: center;
        @media screen and (min-width: 540px){
            font-size: 50px;
        }
    }
    .time-text {
        font-family: $fontSC;
        font-size: 5vw;
        text-align: center;
        @media screen and (min-width: 540px){
            font-size: 25px;
        }
    }
    .block-result{
        .head-result{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 80%;
            margin: auto;
            background-color: $grey;
            padding: 10px 20px;
            border-radius: 200px;
            margin-top: 15px;
            img{
                max-width: 11vw;
                &.head-waste{

                    position: relative;
                    left: 10px;
                }
                &.head-valid{
                    max-width: 16vw;
                }
            }
            @media screen and (min-width: 540px){
                padding: 10px 30px;
                width: 70%;
                img{
                    max-width: 50px;
                    &.head-waste{
                        max-width: 60px;
                    }
                    &.head-valid{
                        max-width: 60px;
                    }
                }
            }
        }
        .content-result{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 75%;
            margin: auto;
            padding: 0px 20px 5px 20px;
            margin-top: 15px;
            border-bottom: 2px solid $darkGrey;
            &:nth-last-child(1){
                border-bottom: 0px solid $darkGrey;
            }
            .waste{
                width: 11vw;
                height: 11vw;
                position: relative;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 7px;
                box-shadow: 0px 0px 15px -11px #000000 inset;
                @media screen and (min-width: 540px){
                    width: 70px;
                    height: 70px;
                }
            }
            img{
                max-width: 11vw;
                &.waste-picture{
                    position: relative;
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    max-width: 8vw;
                    width: 100%;
                }
                &.result-valid{
                    max-width: 9vw;
                }
                @media screen and (min-width: 540px){
                    max-width: 60px;
                    &.waste-picture{
                        max-width: 40px;
                    }
                    &.result-valid{
                        max-width: 40px;
                    }
                }
            }
        }
    }
    .bottom-page{
        position: relative;
    }
    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .btn-wrapper {
        width: 70%;
    }
    .btn-participe{
        width: 80vw;
        height: 12vw;
        margin: 40px auto 0px auto;
        box-shadow: 0px 3px 18px -14px $black;
        span{
            font-size: 3.9vw;
            line-height: 12vw;
        }
        @media screen and (min-width: 540px){
            width: 80%;
            height: 50px;
            span{
                font-size: 20px;
                line-height: 50px;
            }
        }
    }
    .btn-defi, 
    .btn-partenaire {
      width: 63vw;
      height: 13vw;
      box-shadow: 0px 3px 18px -14px $black;
      a{
        font-size: 4.4vw;
        line-height: 13vw;
        color: $white;
      }
      @media screen and (min-width: 540px) {
        min-width: 260px;
        width: auto;
        height: 60px;
        padding: 0 20px;
        a{
          font-size: 18px;
          line-height: 60px;
        }
      }
    }
    .btn-guide{
      width: 90vw;
      height: 13vw;
      margin: 10px auto 20px auto;
      box-shadow: 0px 3px 18px -14px $black;
      a{
        font-size: 4.4vw;
        line-height: 13vw;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        &:before{
          content: "";
          background-image: url("../assets/img/loupe.png");
          width: 5vw;
          height: 5vw;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 4vw;
        }
      }
      @media screen and (min-width: 540px) {
        min-width: 370px;
        width: auto;
        height: 60px;
        padding: 0 20px;
        a{
          font-size: 18px;
          line-height: 60px;
          &:before{
            width: 30px;
            height: 30px;
            margin-right: 20px;
          }
        }
      }
    }
    .link-trier{
        font-size: 4.5vw;
        margin-top: 20px;
        text-decoration: underline;
        text-align: center;
        @media screen and (min-width: 540px) {
            font-size: 22px;
            margin-bottom: 90px;
        }
    }
    .footer-block{
        margin-top: 15px;
    }
}