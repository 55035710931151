.header-block{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 92%;
    margin: 0;
    padding-top: 10px;
    .logo-chrono{
        max-width: 20%;
        max-height: 110px;
    }
    .name-page-block{
        max-width: 100%;
        .name-page{
            text-align: center;
            font-family: $fontSC;
            text-transform: uppercase;
            font-size: 6.2vw;
            padding-top: 5px;
            @media screen and (min-width: 540px) {
                font-size: 28px;
            }
        }
        .line-pict{
            width: 100%;
            height: 100%;
            min-height: 2px;
        }
    }
}
