.scan-page{
    // min-height: 790px;
    min-height: 100%;
    height: auto;
    @media screen and (min-height: 700px){
        min-height: 870px;
    }
    @media screen and (min-height: 800px){
        min-height: 970px;
    }
    @media screen and (min-width: 540px) and (min-height: 600px) {
        min-height: 870px;
    }
    .content-page{
        text-align: center;
    }
    .block-product{
        position: relative;
        .content-product{
            .product-picture{
                max-width: 40%;
                margin: auto;
                @media screen and (min-width: 540px) {
                    max-width: 30%;
                }
            }
            .name-product{
                margin: 0px auto 15px auto;
                font-size: 4.5vw;
                &::first-letter{
                    text-transform: uppercase;
                }
                @media screen and (min-width: 540px) {
                    font-size: 18px;
                }
            }
        }
        .block-verif-scan{
            position: absolute;
            left: 6%;
            bottom: 0%;
            max-width: 9%;
            margin: auto;
            .logo-scan{
              width: 100%;
              position: relative;
              z-index: 1;
            }
            .logo-valid{
                max-width: 90%;
                width: 100%;
                margin: auto;
                position: absolute;
                top: -40%;
                right: -35%;
                z-index: 2;
            }
        }
        .block-change-prod{
            position: absolute;
            right: 6%;
            bottom: 0%;
            max-width: 9%;
            .logo-scan{
                width: 100%;
                position: relative;
                z-index: 1;
            }
        }
    }
    .valid-scan{
        .scan-video{
            div{
                box-shadow: $white 0px 0px 0px 3px inset!important;
            }
        }
    }
    .error-scan{
        .scan-video{
            div{
                box-shadow: $red 0px 0px 0px 3px inset!important;
            }
        }
    }
    .correct-scan{
        .scan-video{
            div{
                box-shadow: $blue 0px 0px 0px 3px inset!important;
            }
        }
    }
    .scan-video{
        div{
            // box-shadow: rgb(255, 255, 255) 0px 0px 0px 3px inset!important;
            width: 80%!important;
            height: 80%!important;
            border: none!important;
            border-radius: 20px!important;
            top: 50%!important;
            transform: translateY(-50%)!important;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        @media screen and (min-width: 540px) {
            width: 80%!important;
            margin: auto;
        }
    }
    .no-scan{
        div{
            box-shadow: $white 0px 0px 0px 3px inset!important;
        }
    }
    .bottom-page{
        position: relative;
    }
}