.end-page{
    // height: 100vh;
    position: relative;
    text-align: center;
    display: flex;
    padding-top: 1vw;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    min-height: 100%;
    @media screen and (min-height: 855px) {
        min-height: 910px;
    }
    @media screen and (min-width: 540px) and (min-height: 600px) {
        min-height: 810px;
    }
    .content-page{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        flex: 1;
        padding: 15px 0;
        position: relative;
        z-index: 2;
    }
    .block-citeo{
        padding-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .line-pict{
            width: 100%;
            height: 100%;
            height: 2px;
        }
        .logo-citeo{
            max-width: 24vw;
            max-height: 56px;
            margin: 0 40px;
        }
    }
    .logo-home{
        max-width: 50vw;
        max-height: 187px;
        margin: 0 auto;
        // margin-top: 50px;
        @media screen and (min-width: 540px) {
            max-width: 270px;
        }
    }
    .title-end {
      font-family: $font;
      font-weight: 500;
      text-align: center;
      font-size: 7vw;
      line-height: 8vw;
      max-width: 85vw;
      margin: 50px auto 20px;
      @media screen and (min-width: 540px) {
        font-size: 32px;
        line-height: 44px;
        max-width: 75%;
      }
    }
    .text-end{
        font-family: $font;
        font-weight: 500;
        text-align: center;
        font-size: 6vw;
        line-height: 7vw;
        max-width: 85vw;
        margin: 20px auto 40px;
        @media screen and (min-width: 540px) {
            font-size: 24px;
            line-height: 37px;
            max-width: 75%;
        }
    }
    .btn-defi,
    .btn-partenaire {
        width: 63vw;
        height: 13vw;
        // margin: 30px auto 0px auto;
        box-shadow: 0px 3px 18px -14px $black;
        a{
            font-size: 4.4vw;
            line-height: 13vw;
            color: $white;
        }
        @media screen and (min-width: 540px) {
            min-width: 260px;
            width: auto;
            height: 60px;
            padding: 0 20px;
            a{
                font-size: 18px;
                line-height: 60px;
            }
        }
    }
    .btn-guide{
        width: 90vw;
        height: 13vw;
        margin: 10px auto 20px auto;
        box-shadow: 0px 3px 18px -14px $black;
        a{
            font-size: 4.4vw;
            line-height: 13vw;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            &:before{
                content: "";
                background-image: url("../assets/img/loupe.png");
                width: 5vw;
                height: 5vw;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                margin-right: 4vw;
            }
        }
        @media screen and (min-width: 540px) {
            min-width: 370px;
            width: auto;
            height: 60px;
            padding: 0 20px;
            a{
                font-size: 18px;
                line-height: 60px;
                &:before{
                    width: 30px;
                    height: 30px;
                    margin-right: 20px;
                }
            }
        }
    }
    .link-trier{
        font-size: 4.5vw;
        margin-top: 20px;
        text-decoration: underline;
        @media screen and (min-width: 540px) {
            font-size: 22px;
            margin-bottom: 90px;
        }
    }
    .block-footer{
        width: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        overflow: hidden;
        //margin-top: -26vw;
        position: relative;
        z-index: 1;
        @media screen and (min-width: 540px) and (min-height: 600px) {
            margin-top: 0;
        }
        .footer-block{
            width: 100%;
            background-color: $white;
            .block-citeo{
                padding-top: 0;
            }
        }
    }
    .chrono-home{
        max-width: 65%;
        margin: 0 auto;
        position: relative;
        //bottom: -30vw;
        z-index: -1;
        margin-bottom: 10px;
        @media screen and (min-width: 540px) {
            max-width: 340px;
            max-height: 232px;
            //bottom: -160px;
        }
    }
}