.validationwaste-block{
    position: fixed;
    background-color: $blacktransp;
    width: 100%;
    height: 100vh;
    top: 0;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
    display: none;
    &.show{
        display: flex;
    }
    .content-validation{
        background-color: $black;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 30vw;
        height: 30vw;
        img{
            width: 60%;
        }
    }
}