@font-face {
    font-family: 'GillSans';
    src:url('../assets/fonts/GillSans/GillSans.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/GillSans/GillSans.woff') format('woff'),
        url('../assets/fonts/GillSans/GillSans.ttf')  format('truetype'),
        url('../assets/fonts/GillSans/GillSans.svg#GillSans') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GraphikWeb-Bold';
    src:url('../assets/fonts/GraphikWeb-Bold/GraphikWeb-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/GraphikWeb-Bold/GraphikWeb-Bold.woff') format('woff'),
        url('../assets/fonts/GraphikWeb-Bold/GraphikWeb-Bold.ttf')  format('truetype'),
        url('../assets/fonts/GraphikWeb-Bold/GraphikWeb-Bold.svg#GraphikWeb-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SansCulottes';
    src:url('../assets/fonts/SansCulottes/SansCulottes.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SansCulottes/SansCulottes.woff') format('woff'),
        url('../assets/fonts/SansCulottes/SansCulottes.ttf')  format('truetype'),
        url('../assets/fonts/SansCulottes/SansCulottes.svg#SansCulottes') format('svg');
    font-weight: normal;
    font-style: normal;
}

$font: 'GillSans';
$fontGraphB: 'GraphikWeb-Bold';
$fontSC: 'SansCulottes';