.gameover-page {
    height: auto;
    position: relative;
    text-align: center;
    min-height: 100%;
    @media screen and (min-height : 855px){
        min-height: 850px;
    }
    @media screen and (min-width: 540px) and (min-height: 600px) {
        min-height: 840px;
    }
    .content-page{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        // padding: 15px 0;
    }
    .title-page{
        font-size: 8vw;
        text-align: center;
        margin-top: 10px;
        @media screen and (min-width: 540px){
            font-size: 60px;
        }
    }
    .text-page{
        font-size: 5vw;
        line-height: 7vw;
        text-align: center;
        max-width: 90%;
        margin: 10px auto;
        @media screen and (min-width: 540px){
            font-size: 21px;
            line-height: 31px;
            max-width: 64%
        }
        .intro {
            padding-bottom: 20px;
        }
        #chrono {
            position: relative;
            img {
                width: 50%;
            }
            .chrono-text {
                font-family: "SansCulottes";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                flex-direction: column;
                text-transform: uppercase;
                .time {
                    font-size: 3em;
                    line-height: 1.1;
                }
                .unit {
                }
            }
        }
    }
    .btn-score {
        display: flex;
        width: 70vw;
        height: 12vw;
        margin: 25px auto 20px auto;
        box-shadow: 0px 3px 18px -14px $black;
        border: none;
        outline: none;
        span{
            font-size: 4.5vw;
            line-height: 12vw;
            width: 100%;
            height: 100%;
            display: block;
        }
        @media screen and (min-width: 540px){
            width: 320px;
            height: 55px;
            span{
                font-size: 22px;
                line-height: 55px;
            }
        }
    }
    .bottom-page{
        position: relative;
    }
}