.btn{
    position: relative;
    border-radius: 30px;
    cursor: pointer;
    z-index: 10;
    box-shadow: 0px 8px 25px -16px $black;
    transition: 0.5s;
    span, a{
        width: 100%;
        height: 100%;
        display: block;
        text-transform: uppercase;
        text-align: center;
        text-decoration: none;
        color: $black;
        font-family: $fontGraphB;
    }
    &:focus, &:active, &:hover{
        transform: scale(0.92);
    }
    &.default{
        background-color: $yellow;
        span, a{
            color: $black;
            font-family: $fontGraphB;
        }
    }
    &.yellow{
        background-color: $yellow;
        span, a{
            color: $black;
            font-family: $fontGraphB;
        }
    }
    &.black{
        background-color: $black;
        span, a{
            color: $white;
            font-family: $fontGraphB;
        }
    }
    &.mustard{
        background-color: $mustard;
        span, a{
            color: $white;
            font-family: $fontGraphB;
        }
    }
    &.green{
        background-color: $greenlight;
        span, a{
            color: $white;
            font-family: $fontGraphB;
        }
    }
    &.grey{
        background-color: $darkGrey;
        span, a{
            color: $white;
            font-family: $fontGraphB;
        }
    }
    &.blue{
        background-color: $blue;
        span, a{
            color: $white;
            font-family: $fontGraphB;
        }
    }
    &.round{
        border-radius: 100%;
    }
}