$timerDimension: 100px;
.timer-group {
    position: relative;
    height: $timerDimension;
    width: $timerDimension;
    color: $black;
    z-index: 100;
    text-align: center;
    font-family: "SansCulottes";
    background: rgba(0, 0, 0, 0.1);
    border-radius: 100%;

    .timer {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: absolute;
        &:after {
            content: "";
            position: absolute;
            background: $white;
            display: block;
            height: 90%;
            width: 90%;
            left: 5%;
            top: 5%;
            border-radius: 50%;
        }
        .hand {
            float: left;
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 50%;
            &:first-child {
                transform: rotate(180deg);
                span {
                    animation-name: spin1;
                }
            }
            &:last-child span {
                animation-name: spin2;
            }
            span {
                $borderColor: #3bc643;
                animation-duration: 5s;
                border: calc(#{$timerDimension} / 2) solid transparent;
                border-bottom-color: $borderColor;
                border-left-color: $borderColor;
                border-radius: 50%;
                display: block;
                height: 0;
                width: 0;
                position: absolute;
                right: 0;
                top: 0;
                transform: rotate(225deg);
                animation-duration: 4s;
                animation-iteration-count: 1;
                animation-timing-function: linear;
            }
        }
    }

    .face {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        left: 50%;
        top: 50%;
        line-height: 1;
        position: absolute;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .time {
            font-size: 33px;
        }
    }
    &.pause {
        .timer .hand span {
            animation-play-state: paused;
        }
    }
    &.stop {
        .timer .hand span {
            display: none;
        }
    }
}
@keyframes spin1 {
    0% {
        transform: rotate(225deg);
    }
    50% {
        transform: rotate(225deg);
    }
    100% {
        transform: rotate(405deg);
    }
}

@keyframes spin2 {
    0% {
        transform: rotate(225deg);
    }
    50% {
        transform: rotate(405deg);
    }
    100% {
        transform: rotate(405deg);
    }
}
